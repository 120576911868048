export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34')
];

export const server_loads = [0,2,3,4,6];

export const dictionary = {
		"/": [~7],
		"/admin/account": [~8],
		"/admin/settings": [~9],
		"/auth/verification-failed": [~10],
		"/auth/verify-me": [~11],
		"/database/[table]": [~12],
		"/onboarding": [~13,[2]],
		"/onboarding/complete": [14,[2]],
		"/onboarding/profile": [15,[2]],
		"/onboarding/workspace": [16,[2]],
		"/preview/[id]": [~17,[3]],
		"/reports": [~18],
		"/reports/[id]": [~19],
		"/reports/[id]/old": [~20],
		"/respond/[id]": [~21,[4]],
		"/signin": [~22],
		"/surveys": [~23],
		"/surveys/create": [25,[5]],
		"/surveys/create/complete": [26,[5]],
		"/surveys/create/details": [27,[5]],
		"/surveys/create/generate": [28,[5]],
		"/surveys/create/preview": [~29,[5,6]],
		"/surveys/create/purpose": [30,[5]],
		"/surveys/create/start": [31,[5]],
		"/surveys/[id]": [~24],
		"/tasks": [32],
		"/team": [~33],
		"/team/[id]": [~34]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';